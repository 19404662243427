<template>
  <b-form-group
    :label="label"
    label-for="live"
  >
    <vue-autosuggest
      id="live"
      v-model="activityNameTemp"
      :suggestions="suggestedLives"
      :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search live by name'}"
      :get-suggestion-value="suggestion => suggestion.item.activity_name"
      @input="onInputChange"
      @selected="selectHandler"
    >
      <template slot-scope="{suggestion}">
        <span class="my-suggestion-item">{{ suggestion.item.activity_name }}</span>
      </template>
    </vue-autosuggest>
  </b-form-group>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'LiveAutoSuggest',
  components: {
    VueAutosuggest,
  },
  props: {
    entityId: { type: Number, default: null },
    label: { type: String, default: '' },
    activityName: { type: String, default: '' },
    liveId: { type: Number, default: null },
    upcomingLive: { type: Boolean, default: false },
  },
  data() {
    return {
      activityNameTemp: this.activityName,
      suggestedLives: [],
    }
  },
  watch: {
    entityId() {
      this.getLive()
    },
  },
  methods: {
    onInputChange(query) {
      if (this.isCriteriaNotSatisfied(query)) {
        return
      }
      if (query.length === 0) {
        this.resetLiveId()
      }

      this.getLive(query)
    },
    isCriteriaNotSatisfied(query) {
      return query.length < 3 && query.length !== 0
    },
    getLive(query) {
      this.$activities.get('internalops/live/list/1/15', {
        params: {
          name: query,
          entity: this.entityId,
          'with-reactions': false,
          upcoming: this.upcomingLive,
        },
      }).then(res => {
        this.suggestedLives = [{ data: res.data.data.data }]
      })
    },
    selectHandler(live) {
      this.$emit('update:liveId', live.item.session_id)
      this.$emit('update:activityId', live.item.id)
    },
    resetLiveId() {
      this.$emit('update:liveId', null)
      this.$emit('update:activityId', null)
    },
  },
}
</script>
<style lang="scss">
@import "src/@core/scss/vue/libs/vue-autosuggest.scss";
</style>
